<template>
  <div class="multi-banner-container" v-if="banners.length">
    <swiper :options="sliderOptions" ref="swiperId">
      <swiper-slide v-for="b in banners" :key="b.bannerId">
        <div v-if="b.isDynamicBanner">
          <div :style="getStyleDynamicDescription(b)" class="banner-dynamic-content">
            {{ b.description }}
          </div>
          <img
            v-if="b.subImage"
            :src="b.subImage"
            :style="getStyleDynamicSubImage(b)"
            class="banner-dynamic-content"
            alt="pm-aktif-logo"
          />
        </div>
        <BrandMediaContentBox
          :mediaSrc="b.image"
          :href="b.link"
          :bannerItem="b"
          :isSliderClick="true"
          @onClickSliderContent="onClickSliderContent(b)"
        />
      </swiper-slide>
      <div class="swiper-button-prev" id="prev-element" slot="button-prev" @click="onClickPrev" />
      <div class="swiper-button-next" id="next-element" slot="button-next" @click="onClickNext" />
    </swiper>
  </div>
</template>

<script>
import BrandMediaContentBox from '@/components/brand/BrandMediaContentBox/BrandMediaContentBox.vue';
import { swiper, swiperSlide } from 'vue-awesome-swiper';
import 'swiper/dist/css/swiper.css';
import { Common } from '@/services/Api/index';
import { BANNER_TYPES, BANNER_MODES } from '@/constants/banner.constants.js';

export default {
  name: 'MultiBannerSlider',
  components: {
    swiper,
    swiperSlide,
    BrandMediaContentBox,
  },
  data() {
    return {
      banners: [],
      position: 0,
      stashedBanners: [],
      initBanners: [],
      isInitBannerLoader: false,
      sliderOptions: {
        slidesPerView: '3.7285',
        spaceBetween: 20,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
    };
  },
  mounted() {
    Common.getMultiBannerData().then(res => {
      this.isInitBannerLoader = true;
      if (res.data && res.data.Data) {
        const dynamicBanners = res.data.Data.dynamicBanners.map(banner => ({
          ...banner,
          image: banner.bannerImage,
          isDynamicBanner: true,
          bannerId: banner.id,
        }));
        const combinedBanners = [...res.data.Data.banners, ...dynamicBanners];

        combinedBanners.sort((a, b) => a.order - b.order);

        this.banners = combinedBanners.map((banner, index) => ({
          ...banner,
          position: index + 1,
        }));

        this.stashedBanners = this.banners.filter(banner => banner.position < 6);

        this.initBanners = this.banners.slice(0, 4);
        setTimeout(() => {
          this.$nextTick(() => {
            this.initObserver();
          });
        }, 1000);
      }
    });
  },
  methods: {
    getStyleDynamicDescription(dynamicBanner) {
      return `top:${dynamicBanner.topDescription}px; left:${
        dynamicBanner.leftDescription
      }px; font-family:${dynamicBanner.fontFamily}; font-size:${
        dynamicBanner.fontSize
      }px; font-style:${dynamicBanner.isBold ? 'bold' : 'none'}; color:${
        dynamicBanner.descriptionColor
      }; width:${dynamicBanner.widthDescription}px; height:${dynamicBanner.heightDescription}px; `;
    },
    getStyleDynamicSubImage(dynamicBanner) {
      return `top:${dynamicBanner.topSubImage}px; left:${dynamicBanner.leftSubImage}px; width: ${dynamicBanner.widthSubImage}px; height: ${dynamicBanner.heightSubImage}px;`;
    },
    initObserver() {
      const observer = new IntersectionObserver(
        entries => {
          if (entries[0].isIntersecting && this.isInitBannerLoader) {
            this.$emit('initSubSlider', this.initBanners);
            this.isInitBannerLoader = false;
            observer.unobserve(entries[0].target);
          }
        },
        { threshold: 0.5 },
      );

      if (this.$el instanceof Element) {
        observer.observe(this.$el);
      }
    },
    onClickSliderContent(bannerItem) {
      this.$emit('onClickSliderContent', { ...bannerItem });

      this.sendClickBannerLog(bannerItem);
    },
    sendClickBannerLog(banner) {
      const bannerMode = banner.isDynamicBanner
        ? BANNER_MODES.DYNAMIC_BANNER
        : BANNER_MODES.CLASSIC_BANNER;

      const logData = {
        bannerId: banner.bannerId,
        bannerType: BANNER_TYPES.MAIN_SLIDER_MULTI_BANNER_ID,
        bannerMode,
      };

      Common.clickBannerLog(logData).catch(error => {
        console.error('Error logging banner click:', error);
      });
    },
    onClickPrev() {
      const prevElement = document.querySelector('#prev-element');
      if (prevElement.className == 'swiper-button-prev swiper-button-disabled') return;

      this.stashedBanners.pop();

      if (this.$refs['swiperId'].swiper.activeIndex == 1) {
        this.$emit('initSubSlider', this.initBanners);
        this.stashedBanners = this.banners.slice(0, 5);
      }
    },
    onClickNext() {
      const lastItem = this.stashedBanners[this.stashedBanners.length - 1];
      if (!lastItem) return;
      this.banners.forEach((element, index, array) => {
        if (element.bannerId == lastItem.bannerId) {
          this.stashedBanners.push(array[index + 1]);
          this.$emit('onSubSliderChanged', lastItem);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.multi-banner-container {
  position: relative;
  /* background-color: #f7f8fa; */
  padding-top: 60px;
  /* padding-bottom: 75px; */
  /deep/.swiper-wrapper {
    height: 350px;
    .swiper-slide {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      .clickable {
        cursor: pointer;
      }
      .bottom-text {
        position: absolute;
        height: 80px;
        bottom: 0;
        left: 0;
        right: 0;
        color: #fff;
        font-size: 14px;
        padding: 42px 16px 22px;
        line-height: 16px;
        background: linear-gradient(0deg, rgba(35, 48, 61, 0.6) 0%, rgba(35, 48, 61, 0) 100%);
      }
    }
  }
  .swiper-button-next,
  .swiper-button-prev {
    opacity: 0;
    position: absolute;
    width: 67px;
    height: 100%;
    background-size: cover;
    z-index: 2;
    top: 23px;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }
  .swiper-button-next {
    right: 0;
    background-image: url('~@/assets/bg/next-shape.png');
  }
  .swiper-button-prev {
    left: 0;
    background-image: url('~@/assets/bg/prev-shape.png');
  }
  &:hover {
    .swiper-button-prev.swiper-button-disabled,
    .swiper-button-next.swiper-button-disabled {
      opacity: 0.3;
      pointer-events: auto;
    }
    .swiper-button-prev,
    .swiper-button-next {
      opacity: 1;
    }
  }
}
.banner-dynamic-content {
  position: absolute;
  z-index: 10;
}
</style>
